import * as React from "react";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import LinkButton from "../../../Button/LinkButton";

import {
  StyledHeading,
  StyledLayout,
  StyledServiceImage
} from "./style";


const SimpleLayout = ({
  node
}) => {
  const number = `0${ node.frontmatter.order }`;

  return (
    <StyledLayout>
      <Link
        to={ node.fields.slug }
      >
        <StyledServiceImage
          image={ getImage(node.frontmatter.serviceImage) }
        />
      </Link>
      <StyledHeading>{ number }</StyledHeading>
      <LinkButton
        linkLocation={ node.fields.slug }
        linkText={ node.frontmatter.title }
      />
    </StyledLayout>
  )
}


export default SimpleLayout;
