import * as React from "react";
import { getImage } from "gatsby-plugin-image";

import SecondaryButton from "../../../Button/SecondaryButton";

import {
  StyledHeading,
  StyledLayout,
  StyledList,
  StyledListItem,
  StyledServiceImage
} from "./style";


const DetailedLayout = ({
  node
}) => {
  return (
    <StyledLayout>
      <StyledServiceImage
        image={ getImage(node.frontmatter.serviceImage) }
      />
      <StyledHeading>{ node.frontmatter.title }</StyledHeading>
      <StyledList>
        { node.frontmatter.serviceItems.map(item => {
          return (
            <StyledListItem>
              { item }
            </StyledListItem>
          )
        })}
      </StyledList>
      <SecondaryButton
        linkLocation={ node.fields.slug }
        linkText="Learn More"
      />
    </StyledLayout>
  )
}


export default DetailedLayout;
