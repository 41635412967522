import * as React from "react";


const Grid = ({
  Component
}) => {
  return (
    <>
      { Component }
    </>
  )
}


export default Grid;
