import * as React from "react";

import {
  LinkArrow,
  LinkContainer,
  StyledExternalLink,
  StyledInternalLink
} from "./style";


const LinkButton = ({
  isExternal,
  linkLocation,
  linkText,
  darkBackground
}) => {
  return (
    <>
      { isExternal &&
        <StyledExternalLink
          href={ linkLocation }
          darkBackground={ darkBackground }
        >
          <LinkContainer>
            <h3>{ linkText }</h3>
            <LinkArrow />
          </LinkContainer>
        </StyledExternalLink>
      }
      { !isExternal &&
        <StyledInternalLink
          to={ linkLocation }
          darkBackground={ darkBackground }
        >
          <LinkContainer>
            <h3>{ linkText }</h3>
            <LinkArrow />
          </LinkContainer>
        </StyledInternalLink>
      }
    </>
  )
}


export default LinkButton;
