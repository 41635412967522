import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  SCREENS
} from "../../../../theme/style-constants";


export const StyledLayout = styled.div`
  display: inline-block;
  width: 280px;
  margin-right: 5vw;
  white-space: normal;

  &:last-child {
    margin-right: 0;
  }
  
  @media (min-width: ${ SCREENS.laptop }) {
    width: 23%;
    margin-right: 0;
  }
`;

export const StyledServiceImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

export const StyledHeading = styled.h3`
  margin: 1rem 0;
`;

export const StyledList = styled.ul`
  margin: 2rem 0;
  padding-left: 1rem;
`;

export const StyledListItem = styled.li`

`;
