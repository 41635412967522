import styled from "styled-components";

import {
  SCREENS
} from "../../theme/style-constants";


export const StyledScroller = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
