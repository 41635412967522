import * as React from "react";

import DetailedLayout from "./ItemLayouts/DetailedLayout";
import SimpleLayout from "./ItemLayouts/SimpleLayout";

import {
  StyledScroller
} from "./style";


const Scroller = ({
  layout,
  nodes
}) => {
  return (
    <StyledScroller>
      { nodes.map(node => {
        switch (layout) {
          case 'detailed':
            return <DetailedLayout node={ node.node } />;
          case 'simple':
            return <SimpleLayout node={ node.node } />;
          default:
            return null;
        }
      })}
    </StyledScroller>
  )
}


export default Scroller;
